/**
 * @file Post message to native bridge
 */
import window from '@@/bits/global'

export default function (msg: string | any, fallbackFunc?: () => void): void {
  if (window.webkit?.messageHandlers?.__iosAppMsgCenter != null) {
    window.webkit.messageHandlers.__iosAppMsgCenter.postMessage(msg)
  } else if (window.__androidAppMsgCenter != null) {
    window.__androidAppMsgCenter.postMessage(JSON.stringify(msg))
  } else if (Boolean(window.Windows) && window.__windowsAppMsgCenter != null) {
    window.__windowsAppMsgCenter.postMessage(msg)
  } else if (window.__macAppMsgCenter != null) {
    window.__macAppMsgCenter.postMessage(msg)
  } else if (window.ReactNativeWebView != null) {
    if (typeof msg !== 'string') {
      msg = JSON.stringify(msg)
    }
    window.ReactNativeWebView.postMessage(msg)
  } else if (fallbackFunc != null) {
    fallbackFunc()
  }
}
